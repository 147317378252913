const CVaskForARaiseAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_Car_CV_Raise_v2_Delivery_v1.m4a'
const MWJetLagAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_CC_MW_JetLagParis_v1_Delivery_v1.m4a'
const MCuAudio =
  'https://static.masterclass.com/PRDT25Q1_OnCall_MC_Leading_v1.m4a'

export const mentorsAudioSamples: {
  [key: string]: { caption: string; audioSrc: string }[]
} = {
  'chris-voss': [
    {
      caption: 'How do I ask for a raise?',
      audioSrc: CVaskForARaiseAudio,
    },
  ],
  'matthew-walker': [
    { caption: 'How can I manage jet lag?', audioSrc: MWJetLagAudio },
  ],
  'mark-cuban': [
    { caption: 'How Do I Lead My Team Through Change?', audioSrc: MCuAudio },
  ],
}
