import { CheckoutTypes } from '..'
import { Faq } from '../components'
import { parseTiers } from '../components/TierProvider'

export const getLoggedOutFaqs = (tiers: CheckoutTypes.Tier[]) => {
  const { monthlyTier, annualTier } = parseTiers(tiers)

  return [
    {
      question: 'What is MasterClass On Call?',
      answer: `MasterClass On Call is an AI coaching product designed to help you reach your full potential—faster. Get expert strategies, insights, and guidance from the world’s best, shaped by their real-world experience. Simply call or text your AI coaches through our website or iOS app to explore topics like communication, relationships, decision-making, and more. Whether you want to sharpen your interpersonal skills or prepare for a difficult conversation, MasterClass On Call provides unlimited, instant guidance—anytime, anywhere.
  
  MasterClass On Call offers personalized world-class wisdom, life advice, direction, and guidance on demand through conversations with our AI coaches anytime through a voice call on our website or iOS app.
  `,
    },
    {
      question: 'Why is MasterClass On Call in beta, and what should I expect?',
      answer:
        'MasterClass On Call is in beta phase, which means you get early access ahead of our official launch. We will be testing and refining various features—and we may ask you for feedback. Your feedback is invaluable as we look to enhance the overall experience. Thank you for joining us in shaping the future of MasterClass On Call!',
    },
    {
      question: 'Who is MasterClass On Call for?',
      answer:
        'MasterClass On Call is for anyone looking for new perspectives, craving specialized knowledge, or looking for a judgment-free environment where they can openly discuss what’s on their mind. It’s for anyone who’s ever done a deep-dive on the web in search of answers to their questions, or spent hours chatting with friends or family about how to overcome challenges or reach their full potential.',
    },
    {
      question: 'What does MasterClass On Call include?',
      answer: `MasterClass On Call offers several innovative features:
  
  <strong>Unparalleled Expertise on Demand:</strong> Get unlimited access to the knowledge and experiences of the world’s best—anytime, anywhere. MasterClass On Call is built in partnership with the real-life experts and trained on various data sources, including content exclusive to MasterClass.
  
  <strong>Personalized & Actionable Guidance:</strong> Get tailored advice specific to your unique situation and challenges. Engage in quick or in-depth, one-on-one conversations that adapt to your needs and help you overcome any obstacle.
  
  <strong>Natural, Intuitive Conversations:</strong> Talk things through like you would with a wise friend, always ready to discuss anything big or small—and for as long as you need. Simply chat through the On Call iOS app or website.
  
  <strong>Private and Judgment-Free:</strong> Discuss your challenges openly in a secure setting without fear of judgment. MasterClass is committed to protecting your conversations and data.`,
    },
    {
      question: 'Why is MasterClass On Call reliable?',
      answer:
        'Our unique AI has been meticulously trained on data sources curated by MasterClass in partnership with the real-life expert, which includes some content exclusive to MasterClass. This includes specialized knowledge repositories and real-life stories, enhanced by the direct input and oversight of the real-life experts themselves. This collaboration helps our AI coaches mimic the conversation style and problem-solving approach of their real-life counterparts, and that their advice is insightful, personalized, and relevant.',
    },
    {
      question: 'How much does MasterClass On Call cost?',
      answer:
        monthlyTier &&
        annualTier &&
        `MasterClass On Call offers flexible pricing options: ${monthlyTier?.internalData.prices.fullMonthlyWithCurrency}/month, billed monthly, or ${annualTier?.internalData.prices.fullWithCurrency}/year, billed annually. Both subscription options come with a 7-day satisfaction guarantee. Members get access to all available AI coaches across various fields.`,
    },
    {
      question: 'How does the 7-day satisfaction guarantee work?',
      answer:
        'If you’re not satisfied with your On Call membership, we offer a full refund within the first 7 days (or for the length of time that is required by applicable law in your jurisdiction) of your initial purchase. Our satisfaction guarantee is designed to give you the confidence and peace of mind to explore everything MasterClass On Call has to offer, commitment-free. Please note, this guarantee does not apply to renewals. You can explore all the features and benefits of MasterClass On Call. If you are not satisfied with your membership during the satisfaction guarantee period, please contact support at support@masterclass.com to request a refund.',
    },
    {
      question: 'What devices support MasterClass On Call?',
      answer:
        'MasterClass On Call is accessible on iOS phones and tablets, as well as desktop and mobile web browsers.',
    },
  ] as Faq[]
}
