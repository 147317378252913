export interface RowAnalytics {
  rowName: string
  rowPositionIndex: number
}

export const ROW_TRACKING = {
  hero: {
    rowName: 'hero',
    rowPositionIndex: '0',
  },
  broughtToYouBy: {
    rowName: 'brought to you by masterclass',
    rowPositionIndex: 1,
  },
  goals: {
    rowName: 'goals',
    rowPositionIndex: 2,
  },
  raiseYourExpectations: {
    rowName: 'raise your expectations',
    rowPositionIndex: 3,
  },
  aiPoweredByPros: {
    rowName: 'ai powered by the pros',
    rowPositionIndex: 4,
  },
  yourAiMentors: {
    rowName: 'your ai mentors',
    rowPositionIndex: 5,
  },
  comingSoon: {
    rowName: 'coming soon',
    rowPositionIndex: 6,
  },
  conversationStarters: {
    rowName: 'conversation starters',
    rowPositionIndex: 7,
  },
  testimonials: {
    rowName: 'testimonials',
    rowPositionIndex: 8,
  },
  plans: {
    rowName: 'plans',
    rowPositionIndex: 9,
  },
  faqs: {
    rowName: 'faq',
    rowPositionIndex: 10,
  },
  footer: {
    rowName: 'tos',
    rowPositionIndex: 11,
  },
  // Should always be the last row position index
  stickyFooter: {
    rowName: 'sticky footer',
    rowPositionIndex: 12,
  },
}
